import React, { useCallback, useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { Inline, Box, xcss } from '@atlaskit/primitives';

import { GlobalInviteWithLazyLoadedRelayProvider } from '@atlassian/global-invite';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { PanelSplitter } from '@atlassian/navigation-system/layout/panel-splitter';
import {
	SideNav,
	useToggleSideNav,
	SideNavFooter,
} from '@atlassian/navigation-system/layout/side-nav';

import {
	Attribution,
	ErrorDisplay,
	isUnauthorizedError,
	TransparentErrorBoundary,
} from '@confluence/error-boundary';
import { type WithFlagsProps, withFlags } from '@confluence/flags';
import { useSettingsBackButtonUtils } from '@confluence/admin-side-navigation';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import {
	AdminNavigationLoader,
	SpaceAdminNavigation,
	SpaceNavigation,
	SpaceNavigationLoadingSkeleton,
	GlobalNavigation,
	HubSettingsNavigation,
	StarredSpacesList,
} from '@confluence/side-navigation';
import { isDialogShown } from '@confluence/dialogs';
import {
	GeneralShortcutListener,
	ShortcutListener,
	SIDE_NAVIGATION_SHORTCUT,
	SIDE_NAVIGATION_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { RoutesContext } from '@confluence/route-manager';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { usePageSpaceKey } from '@confluence/page-context';
import { WHITEBOARD_IFRAME_TITLE } from '@confluence/whiteboard-utils/entry-points/whiteboardConstants';
import {
	CoordinatedNav4OnboardingModal,
	type Nav4ChangeboardingSpotlightProps,
} from '@confluence/nav4-onboarding';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { AnonymousAccessSideNavMessage } from '@confluence/anonymous-access/entry-points/AnonymousAccessSideNavMessage';
import { useIsUserViewingSpaceAsAnon } from '@confluence/anonymous-access/entry-points/useIsUserViewingSpaceAsAnon';
import { usePersistentInviteButtonEligible } from '@confluence/persistent-invite-button/entry-points/usePersistentInviteButtonEligible';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';
import { useSessionData } from '@confluence/session-data';
import { PostOfficeConfluenceSideNavPlacement } from '@confluence/experiment-post-office-side-nav';

import { SideNavigationContent } from './SideNavigationContent';
import { useSideNavInitialState } from './useSideNavInitialState';
import { useScrollTree } from './useScrollTree';
import {
	ADMIN_HOME_ID,
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_BLOG_ID,
	CONTAINER_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
	CONTAINER_HOME_LOADING_ID,
	PRODUCT_HOME_ID,
} from './paths';
import { Nav4GiveFeedbackButton } from './Nav4GiveFeedbackButton';

const i18n = defineMessages({
	dragHandle: {
		id: 'page-layout.side-navigation.drag-handle',
		description:
			'A label to tell visually impaired users that the selected element is the side navigation drag handle for resizing the sidebar',
		defaultMessage: 'Side Navigation Drag Handle',
	},
	globalInviteButtonLabel: {
		id: 'page-layout.side-navigation.global-invite-button-label',
		description: 'Text content of the button that triggers the modal dialog.',
		defaultMessage: 'Invite people',
	},
});
const resize = () => {
	window.dispatchEvent(new Event('resize'));
};
const ExpandKeyboardShortcut = ({ onToggleFromShortcut }) => {
	const isOnEditRoute = useIsEditorPage();
	const toggleSideNav = useToggleSideNav();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const toggle = useCallback(() => {
		if (!isDialogShown()) {
			onToggleFromShortcut();
			toggleSideNav();
		}
	}, [onToggleFromShortcut, toggleSideNav]);
	return isLiveEditMode || isOnEditRoute ? (
		<ShortcutListener accelerator={SIDE_NAVIGATION_SHORTCUT_IN_EDIT} listener={toggle} />
	) : (
		<GeneralShortcutListener accelerator={SIDE_NAVIGATION_SHORTCUT} listener={toggle} />
	);
};

const useIsModeGlobal = () => {
	const { match } = useContext(RoutesContext);

	const [spaceKey] = usePageSpaceKey();

	return match?.query?.mode === 'global' || isCompanyHubSpaceKey(spaceKey);
};

//todo: are all these views still relevant? I'm not certain they are
const getProductSideNavigation = (
	view: string | undefined,
	isSpaceSettingsScreen: boolean,
	isModeGlobal: boolean,
	isOnAnySettingsScreen: boolean,
	activeNav4Spotlight,
	setActiveNav4Spotlight,
	unhandledError: ApolloError | undefined,
	isLicensed: boolean,
) => {
	// hide product nav if mode=global unless we're on a settings page
	if (isModeGlobal && !isOnAnySettingsScreen) {
		return null;
	}

	switch (view) {
		case CONTAINER_HOME_ID:
			return (
				<SpaceNavigation
					isSpaceSettingsScreen={isSpaceSettingsScreen}
					activeNav4Spotlight={activeNav4Spotlight}
					setActiveNav4Spotlight={setActiveNav4Spotlight}
					{...(unhandledError && { unhandledError })}
				/>
			);
		case CONTAINER_BLOG_ID:
			return (
				<SpaceNavigation
					isBlogNavigation
					isSpaceSettingsScreen={false}
					activeNav4Spotlight={activeNav4Spotlight}
					setActiveNav4Spotlight={setActiveNav4Spotlight}
				/>
			);
		case CONTAINER_HOME_LOADING_ID:
			return <SpaceNavigationLoadingSkeleton />;
		case CONTAINER_ADMIN_HOME_ID:
			return <SpaceAdminNavigation />;
		case COMPANY_HUB_SETTINGS_ID:
			return <HubSettingsNavigation />;
		case ADMIN_HOME_ID:
			return <AdminNavigationLoader />;
	}
	return isLicensed && fg('confluence_nav_4_beta') ? <StarredSpacesList /> : null;
};

export const SideNavigation = ({
	view,
	isSpaceSettingsScreen,
	activeNav4Spotlight,
	setActiveNav4Spotlight,
}: {
	view: string | undefined;
	isSpaceSettingsScreen: boolean;
	skiplinkText?: string;
} & Partial<Nav4ChangeboardingSpotlightProps>) => {
	const intl = useIntl();
	const isModeGlobal = useIsModeGlobal(); //instead of disabling the whole left nav, now mode=Global will disable only the space Navigation
	const { initialCollapsedState, initialWidth, shouldForceExpand } = useSideNavInitialState();
	const { scrollTree } = useScrollTree({
		view,
	});
	const { saveCurrentRoute } = useSettingsBackButtonUtils();
	const [spaceKey] = usePageSpaceKey();
	const { isLicensed } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isOnEditRoute = useIsEditorPage();
	const isPageWithinSpaceSettings = view === CONTAINER_ADMIN_HOME_ID;
	const isPageWithinSiteSettings = view === ADMIN_HOME_ID;
	const isHubSettingsScreen = view === COMPANY_HUB_SETTINGS_ID;
	const isOnAnySettingsScreen =
		isPageWithinSpaceSettings || isPageWithinSiteSettings || isHubSettingsScreen;

	const {
		isUserViewingSpaceAsAnon,
		loading: anonymousAccessQueryLoading,
		error: anonymousAccessQueryError,
	} = useIsUserViewingSpaceAsAnon({ spaceKey: spaceKey || '' });

	const productSideNavigation = getProductSideNavigation(
		view,
		isSpaceSettingsScreen,
		isModeGlobal,
		isOnAnySettingsScreen,
		activeNav4Spotlight,
		setActiveNav4Spotlight,
		anonymousAccessQueryError,
		isLicensed,
	);

	const [triggeredByShortcut, setTriggeredByShortcut] = useState(false);

	const fireSidebarToggleAnalytics = useCallback(
		(action: 'collapsed' | 'expanded', triggeredBy: 'keyboardShortcut' | 'click') => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action,
					actionSubject: 'spaceNavigation',
					source: 'containerNavigation',
					attributes: {
						isOnEditRoute,
						navigationLayer: 'global',
						navigationContainer: 'top',
						navVersion: '4',
						triggeredBy,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, isOnEditRoute],
	);

	const onCollapse = useCallback(() => {
		confluenceLocalStorageInstance.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
			'collapsed',
		);

		resize();
		fireSidebarToggleAnalytics('collapsed', triggeredByShortcut ? 'keyboardShortcut' : 'click');
		setTriggeredByShortcut(false);
	}, [fireSidebarToggleAnalytics, triggeredByShortcut]);

	const onExpand = useCallback(() => {
		confluenceLocalStorageInstance.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
			'expanded',
		);
		scrollTree();
		resize();
		fireSidebarToggleAnalytics('expanded', triggeredByShortcut ? 'keyboardShortcut' : 'click');
		setTriggeredByShortcut(false);
	}, [scrollTree, fireSidebarToggleAnalytics, triggeredByShortcut]);

	const handleToggleFromShortcut = useCallback(() => {
		setTriggeredByShortcut(true);
	}, []);

	// If current route is not a settings page, save the current route to session storage.
	// The saved route is read by the back button whenever the user lands in Site or Space settings in the same session.
	if (!isPageWithinSpaceSettings && !isPageWithinSiteSettings && !isHubSettingsScreen) {
		saveCurrentRoute();
	}

	return (
		<SideNav
			defaultWidth={initialWidth}
			defaultCollapsed={initialCollapsedState === 'collapsed'}
			onExpand={onExpand}
			onCollapse={onCollapse}
			id="side-navigation"
		>
			<UFOSegment name="side-nav">
				<SideNavigationContent>
					<MenuList>
						{!isOnAnySettingsScreen && (
							<GlobalNavigation withVisibleAppShortcuts={view === PRODUCT_HOME_ID} />
						)}
						{productSideNavigation}
					</MenuList>
					{!shouldForceExpand && (
						<ExpandKeyboardShortcut onToggleFromShortcut={handleToggleFromShortcut} />
					)}
					<PanelSplitter
						onResizeEnd={onResizeEnd}
						onResizeStart={onResizeStart}
						label={intl.formatMessage(i18n.dragHandle)}
					/>
				</SideNavigationContent>
				{isUserViewingSpaceAsAnon ? (
					<AnonymousAccessSideNavMessage />
				) : (
					!anonymousAccessQueryLoading && !anonymousAccessQueryError && <SideNavFooterWrapped />
				)}
			</UFOSegment>
			<CoordinatedNav4OnboardingModal />
		</SideNav>
	);
};

const SideNavFooterWrapped = () =>
	fg('post-office-connie-side-nav-killswitch') ? (
		<>
			<PostOfficeConfluenceSideNavPlacement nav4 />
			<SideNavFooter>
				<TransparentErrorBoundary attribution={Attribution.NAVX}>
					<SideNavFooterContent />
				</TransparentErrorBoundary>
			</SideNavFooter>
		</>
	) : (
		<SideNavFooter>
			<TransparentErrorBoundary attribution={Attribution.NAVX}>
				<SideNavFooterContent />
			</TransparentErrorBoundary>
		</SideNavFooter>
	);

const globalInviteButtonStyles = xcss({
	flexGrow: 1,
});

const Nav4SideNavFooterContent = withFlags(({ flags }: WithFlagsProps) => {
	const intl = useIntl();

	return (
		<Inline space="space.100">
			<Box xcss={globalInviteButtonStyles}>
				<GlobalInviteWithLazyLoadedRelayProvider product="confluence" showFlag={flags.showFlag}>
					{intl.formatMessage(i18n.globalInviteButtonLabel)}
				</GlobalInviteWithLazyLoadedRelayProvider>
			</Box>
			<Nav4GiveFeedbackButton />
		</Inline>
	);
});

const SideNavFooterContent = () => {
	const { error, loading, isEligible } = usePersistentInviteButtonEligible();
	if (loading) return <Nav4GiveFeedbackButton />;

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
		} else {
			return (
				<ErrorDisplay error={error}>
					<Nav4GiveFeedbackButton />
				</ErrorDisplay>
			);
		}
	}

	return isEligible && fg('confluence_nav4_global-invite') ? (
		<Nav4SideNavFooterContent />
	) : (
		<Nav4GiveFeedbackButton />
	);
};

// CCT-951 TEMPORARY WORKAROUND to allow resizing of side-nav when viewing a Whiteboard (rendered in an iFrame)
// Can be removed when DSP-5931 is fixed and @atlaskit/page-layout is bumped
const onResizeStart = () => {
	(
		document.querySelector(`iframe[title="${WHITEBOARD_IFRAME_TITLE}"]`) as HTMLElement
	)?.style.setProperty('pointer-events', 'none');
};

const onResizeEnd = ({ finalWidth }: { finalWidth: number }) => {
	// CCT-951 TEMPORARY WORKAROUND to allow resizing of side-nav when viewing a Whiteboard (rendered in an iFrame)
	// Can be removed when DSP-5931 is fixed and @atlaskit/page-layout is bumped
	(
		document.querySelector(`iframe[title="${WHITEBOARD_IFRAME_TITLE}"]`) as HTMLElement
	)?.style.removeProperty('pointer-events');

	confluenceLocalStorageInstance.setItem(
		PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_WIDTH,
		finalWidth,
	);
};
