import type { FC, ReactNode } from 'react';
import React, { useState, memo } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl-next';

import { Section } from '@atlaskit/menu';

import AtlassianAccountMenu from '@atlassian/account-menu';

import { SYSTEM_USER, useWebItemLocation } from '@confluence/web-item-location';
import { useSessionData } from '@confluence/session-data';
import { usePersistentUpgradeMenuSection } from '@confluence/persistent-upgrade';
import { DialogsStateContainer } from '@confluence/dialogs';
import { FlagsStateContainer } from '@confluence/flags';
import { useTrashedSpace } from '@confluence/space-directory';
import { fg } from '@confluence/feature-gating';
import { getLoginLink } from '@confluence/login-if-anonymous/entry-points/getLoginLink';
import { useThemingState } from '@confluence/theming/entry-points/useThemingState';
import { useConfluenceStandardToPremiumPUBTouchpoint } from '@confluence/experiment-edition-awareness-for-standard-to-premium';

import type { FilteredItems } from './useWebItemMapper';
import { useWebItemMapper } from './useWebItemMapper';
import type { WebItem } from './mapWebItem';
import { TrashedPersonalSpacesQuery } from './TrashedPersonalSpacesQuery.graphql';
import type {
	TrashedPersonalSpacesQuery as TrashedPersonalSpacesQueryType,
	TrashedPersonalSpacesQueryVariables,
} from './__types__/TrashedPersonalSpacesQuery';
import { useNav4OptOutMenuItem } from './useNav4OptOutMenuItem';
import { PopupMenuItemComponent } from './PopupMenuItem';

const INCLUDED_WEB_ITEMS = [
	'com.atlassian.confluence.plugins.confluence-frontend-support:adg3-give-feedback',
	'confluence.user.menu:create-personal-space',
	'confluence.user.menu:personal-space',
	'confluence.user.menu:admin-restore-personal-space',
	'com.atlassian.confluence.plugins.confluence-inline-tasks:my-tasks-menu-item',
	'confluence.user.menu:settings',
	'com.atlassian.plugins.atlassian-connect-plugin:confluence-chats-integration__profile',
];

type emails = {
	value: string | null;
};

type photos = {
	value: string | null;
};

type User = {
	id: string | null;
	displayName: string | null;
	emails: (emails | null)[] | null;
	photos: (photos | null)[] | null;
};

type AccountMenuProps = {
	user: User;
	dialogFn(arg: (onClose: () => void) => ReactNode): void;
};

type AccountMenuComponentProps = AccountMenuProps & {
	webItems: WebItem[];
	webSections: (string | null)[];
	dialogs: DialogsStateContainer;
	flags: FlagsStateContainer;
};

const getManageAccountUrl = (destinationAccountEmail: string, environment: string) => {
	const loginLink = getLoginLink(getAtlassianPersonalSettingsLink(environment));

	return `${loginLink}&login_hint=${encodeURIComponent(destinationAccountEmail)}&prompt=none`;
};

const getSwitchAccountUrl = () => {
	const loginLink = getLoginLink();

	return `${loginLink}&prompt=select_account`;
};

const getAtlassianPersonalSettingsLink = (environment: string) => {
	return environment === 'PRODUCTION'
		? 'https://id.atlassian.com/manage-profile/profile-and-visibility'
		: 'https://id.stg.internal.atlassian.com/manage-profile/profile-and-visibility';
};

const AccountMenuComponent: FC<AccountMenuComponentProps> = memo(
	({ user, webItems, webSections, dialogFn, flags }) => {
		const { formatMessage } = useIntl();
		const { environment } = useSessionData();

		const userId = user?.id || '';
		const profileIconUrl = user?.photos?.[0]?.value || '';
		const accountEmail = user?.emails?.[0]?.value || '';
		const accountDisplayName = user?.displayName || '';
		const manageAccountUrl = getManageAccountUrl(accountEmail, environment);
		const switchAccountUrl = getSwitchAccountUrl();

		const [themeState, themingActions] = useThemingState();
		const persistentUpgradeMenuSection = usePersistentUpgradeMenuSection() as Array<FilteredItems>;
		const standardToPremiumEASection =
			useConfluenceStandardToPremiumPUBTouchpoint() as Array<FilteredItems>;
		const nav4OptItem = useNav4OptOutMenuItem();
		const [itemSelected, setItemSelected] = useState<string | undefined>(undefined);

		// if webItems has 'admin-restore-personal-space', then we need to call the query to get the trashed personal space data
		const hasTrashedPersonalSpace = webItems.some(
			(obj) => obj.completeKey === 'confluence.user.menu:admin-restore-personal-space',
		);

		const { data } = useQuery<TrashedPersonalSpacesQueryType, TrashedPersonalSpacesQueryVariables>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			TrashedPersonalSpacesQuery,
			{
				skip: !hasTrashedPersonalSpace,
				variables: {
					creatorAccountIds: [userId || ''],
				},
			},
		);

		const node = data?.spaces?.nodes?.[0];
		const spaceKey = node?.key || '';
		const spaceId = node?.id || '';
		const spaceAlias = node?.alias || '';

		const { handleRestoreClick } = useTrashedSpace({
			spaceKey,
			spaceAlias,
			spaceId,
			flags,
			source: 'profileMenu',
		});

		const callbackActions = {
			'user-theme': () => {
				itemSelected === 'user-theme' ? setItemSelected(undefined) : setItemSelected('user-theme');
			},
			'admin-restore-personal-space': () => handleRestoreClick(),
		};

		const menuItems: Array<FilteredItems> = useWebItemMapper(webItems, webSections, {
			userId,
			callbackActions,
		});

		const nav4OptItems = fg('confluence_nav_4_opt') ? [nav4OptItem] : [];

		const accountMenuItems = [
			...persistentUpgradeMenuSection,
			...standardToPremiumEASection,
			...menuItems,
			...nav4OptItems,
		];

		const getSectionTitle = (group: FilteredItems) => {
			if (group.heading) {
				if (typeof group.heading === 'string') {
					return group.heading;
				} else {
					return formatMessage(group.heading);
				}
			} else {
				return undefined;
			}
		};

		return (
			<AtlassianAccountMenu
				name={accountDisplayName}
				picture={profileIconUrl}
				email={accountEmail}
				viewProfileLink="/wiki/users/viewmyprofile.action"
				manageProfileLink={manageAccountUrl}
				logOutLink="/wiki/logout.action"
				currentTheme={themeState?.theme?.colorMode}
				onThemeChange={themingActions.setColorModePreference}
				switchAccountLink={switchAccountUrl}
				extraMenuItems={accountMenuItems.map((group) => (
					<Section key={getSectionTitle(group)} hasSeparator title={getSectionTitle(group)}>
						{group.items.map((item) => {
							const itemGetDialog = item.hasOwnProperty('getDialog') ? item.getDialog : null;
							const dialogHandler = itemGetDialog
								? () => {
										dialogFn(itemGetDialog);
									}
								: null;
							const onClick = item.action || dialogHandler;
							const closeAction = item.closeAction;
							const onClickHandler = () => {
								if (closeAction) {
									closeAction();
								}
								if (onClick) {
									onClick();
								}
							};

							return (
								<PopupMenuItemComponent
									key={item.key}
									item={item}
									onClickHandler={onClickHandler}
									closeMenu={closeAction || (() => {})}
									isSelected={itemSelected === item.key}
								/>
							);
						})}
					</Section>
				))}
			/>
		);
	},
);

export const AccountMenu: FC<AccountMenuProps> = memo(({ user, dialogFn }) => {
	const { webItems, webSections } = useWebItemLocation({
		location: SYSTEM_USER,
		allowedWebItems: INCLUDED_WEB_ITEMS,
	});

	return (
		<Subscribe to={[DialogsStateContainer, FlagsStateContainer]}>
			{(dialogs: DialogsStateContainer, flags: FlagsStateContainer) => {
				return (
					<AccountMenuComponent
						user={user}
						dialogFn={dialogFn}
						// @ts-expect-error - Type 'WebItem[]' is not assignable to type 'WebItem[]'
						webItems={webItems}
						webSections={webSections}
						dialogs={dialogs}
						flags={flags}
					/>
				);
			}}
		</Subscribe>
	);
});
