import React, { useState, useCallback } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import { preloadProfileMenu } from '@confluence/nav-menus/entry-points/preloadProfileMenu';
import { AccountMenu } from '@confluence/nav-menus/entry-points/AccountMenu';

import type { TopNavigationQuery_user as User } from './__types__/TopNavigationQuery';

type AccountButtonProps = {
	user: User;
};

export const AccountButton = ({ user }: AccountButtonProps) => {
	const [activeDialog, setActiveDialog] = useState(null);

	const closeDialog = useCallback(() => {
		setActiveDialog(null);
	}, [setActiveDialog]);

	const openDialog = useCallback(
		(dialog: any) => {
			setActiveDialog(dialog(closeDialog));
		},
		[setActiveDialog, closeDialog],
	);

	const preloadMenu = useCallback(() => {
		void preloadProfileMenu();
	}, []);

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onMouseEnter={preloadMenu}>
				<AccountMenu user={user} dialogFn={openDialog} />
			</div>
			<ModalTransition>{activeDialog}</ModalTransition>
		</>
	);
};
